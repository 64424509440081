<template>
    <Page :title="$t('profile.title')">
        <div
            v-if="localeSwitcher"
            class="form-field"
        >
            <label class="label">{{ $t('profile.language') }}</label>
            <SelectInput
                :clearable="false"
                :options="locales"
                :modelValue="locale"
                object-mode
                @update:modelValue="handleSelectLocale"
            />
        </div>
        <div class="form-field">
            <button
                type="button"
                class="link"
                @click="handleChangePassword"
            >
                {{ $t('profile.change_password') }}
            </button>
        </div>
    </Page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import locales from '@/locales';
import Page from '@/components/ui/Page';
import SelectInput from '@/components/ui/SelectInput';

export default {
    components: { SelectInput, Page },

    data() {
        return {
            localeSwitcher: process.env.VUE_APP_LOCALE_SWITCHER,
            locales,
        };
    },

    computed: {
        ...mapGetters({
            localeKey: 'settings/locale',
        }),

        locale() {
            return this.locales.find(locale => locale.key === this.localeKey);
        },
    },

    methods: {
        ...mapActions({
            setLocale: 'settings/setLocale',
        }),

        handleSelectLocale(option) {
            this.setLocale(option.key);
        },

        handleChangePassword() {
            this.$router.push({ name: 'profile.change_password' });
        },
    },
};
</script>
